import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/journal.css"

const JournalPage = props => {
  const [journal, setJournal] = React.useState(null)

  React.useEffect(() => {
    async function fetchData() {
      if (typeof window !== "undefined" && journal === null) {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        const response = await fetch(
          `https://europe-west1-writetf-45ec9.cloudfunctions.net/journal?id=${props.params["*"]}&token=${params.token}`
        )
        const data = await response.json()
        setJournal(data)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.params])

  function goNext(e) {
    const isNext = e.clientX / window.innerWidth > 0.33
    const slide = e.target.closest(".slide")
    const scrollto = isNext ? slide.nextSibling : slide.previousSibling
    if (scrollto) scrollto.scrollIntoView(true)
  }

  return (
    <Layout fullwidth={true}>
      <Seo title="Journal" />
      {journal === null && <p>loading Journal</p>}
      {journal !== null && (
        <div className="journal">
          <div
            className="slide"
            onClick={goNext}
            onKeyDown={goNext}
            role="button"
            tabIndex="0"
          >
            <h1>{journal.title}</h1>
            <h3>{journal.description}</h3>
            {journal.cover && (
              <img
                className="image cover"
                src={journal.cover.picture960}
                alt="Journal Cover"
              />
            )}
            <div className="spacer" />
          </div>
          {journal.blocks &&
            journal.blocks.length &&
            journal.blocks.map(id => {
              const block = journal.content[id]
              if (block.type === "title") {
                return (
                  <div
                    key={id}
                    onClick={goNext}
                    onKeyDown={goNext}
                    role="button"
                    tabIndex={id}
                    className="slide"
                  >
                    <h3>{block.title}</h3>
                  </div>
                )
              } else if (block.type === "description") {
                return (
                  <div
                    key={id}
                    onClick={goNext}
                    onKeyDown={goNext}
                    role="button"
                    tabIndex={id}
                    className="slide"
                  >
                    <p>{block.description}</p>
                  </div>
                )
              } else if (block.type === "image") {
                return (
                  <div
                    key={id}
                    onClick={goNext}
                    onKeyDown={goNext}
                    role="button"
                    tabIndex={id}
                    className="slide"
                  >
                    <img
                      className="image"
                      src={block.image.picture960}
                      alt={block.caption || "Journal"}
                    />
                    {block.caption !== undefined && block.caption.length > 0 && (
                      <em style={{ display: "block", marginBottom: 18 }}>
                        <small>{block.caption}</small>
                      </em>
                    )}
                  </div>
                )
              }
              return <div key={id}>{JSON.stringify(block)}</div>
            })}
        </div>
      )}
    </Layout>
  )
}

export default JournalPage
